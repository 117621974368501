import React  from "react"

//styles
import "../styles/index.css"
import "../styles/header.css"
import "../styles/banner.css"
import "../styles/footer.css"
import "../styles/sbanner.css"
import "../styles/services.css"
import "../styles/servicesTemp.css"
import "../styles/contact.css"
import "../styles/careers.css"
import "../styles/serviceCard.css"
import "../styles/ourStory.css"
import "../styles/blog.css"

//testing. remove later
// components
import Header from "../components/Header.js";
import Banner from "../components/Banner.js"
import Footer from "../components/Footer.js";
import ServiceCard from "../components/ServiceCard.js";

// components
import Layout from "../components/Layout";

import data from "../data/blogs.json"


const BlogPage = () => {
//take data from data.json file
 let div5 = []
 for(let i = 0; i < data.length; i++){

    div5.push(<li>{data[i].title}</li>)
    div5.push(<li>{data[i].date}</li>)
    div5.push(<li>{data[i].description}</li>)
  } 
 
  // Generate an array of JSX elements representing blog posts
 /* const blogPosts = data.map((post, index) => {
    <div key={index} className="blog-post">
      <h2>{post.title}</h2>
      <p>{post.date}</p>
      <p>{post.description}</p>
    </div>
  )};
};*/
  
//toggle function
  const togglePost = () => {
    var postContent = document.getElementById("careoptionpost1");
    if (postContent.style.display === "block") {
      postContent.style.display = "none";
    } else {
      postContent.style.display = "block";
    }
  };

  // Event listener for the show post button
  
//{div5} where blogPosts is

  return (
     <Layout title="Blog" path="/ Blog">
     <div>
     <title>Co-op Homecare | Blog</title>
     <div class="blog-content">
      <div class="main">
      		<div class="main1">Main content</div>
          <div class = "careoptionpost1" id="careoptionpost1">
              
                {div5}
          </div>
            
          
          </div>
     <div class="sidebar">
        <div class="sidebar2"></div>
        <div class="label"> SEARCH
            <form>
              <input type="text" placeholder="Search..." class="search-box" />
              <button type="submit" class="search-button">Search</button>
            </form>
        </div>
        <div class="sidebar2"></div>
        <div class="label"> CATEGORIES</div>
        <div class="sidebar1" id="carelink1" onClick={togglePost}><a href="#" >Care Options</a></div>
        
        <div class="sidebar1"><a href="#">Selecting Care</a></div>
         
       
      </div>
     </div>
     </div>

      


      </Layout>
  )
   
}


export default BlogPage
