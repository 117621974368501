import React from "react";
import { Link } from 'gatsby'

const ServiceCard = ({title, description}) => {
    return(
        <article> 
            <Link to="/services" className="services-label">
                <h3>{title}</h3>
                <br></br>
                <p>{description}</p>
            </Link>
        </article>
    )
}
export default ServiceCard