import React from "react"

// images
import banner from "../images/homeBanner.png"
import servicesBanner from "../images/servicesBanner.jpg"
import contactBanner from "../images/contactBanner.jpg"
import storyBanner from "../images/storyBanner.jpg"
import careersBanner from "../images/careersBanner.png"

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
const home = <FontAwesomeIcon icon={faHome}/>

const SBanner = ({title, path}) => {

    /* Get the banner image corresponding to the page */
    function getImg(path) {
        switch (path) {
            case "/ Our Story":
                return storyBanner; 
            case "/ Our Services":
                return servicesBanner; 
            case "/ Contact Us":
                return contactBanner; 
            case "/ Careers":
                return careersBanner; 
            default:
                return banner; 
        }
    }
  
    return(
        <section>
            <div 
            style={{
                
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100vw",
                height: "500px",
                background: `linear-gradient(89.56deg, #271F21 0.34%, rgba(0, 0, 0, 0) 80.7%), url(${getImg(path)})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat"
            }}> 
                <div id="sbanner-content">
                    <h1> {title} </h1> 
                </div>       
            </div>
            <div id="dir-parent">
                <div id="dir-content">
                    <p> <a href="/">{home} </a>{path}</p>
                </div>
            </div>
        </section>
    )
}
export default SBanner
