import React from 'react'
import Navbar from './Header'
import SBanner from './SBanner'
import Footer from './Footer'

export default function Layout({children, title, path}){
    return(
        <div>
            <Navbar/>
            <SBanner title={title} path={path}></SBanner>
            <div>
                {children}
            </div>
            <Footer></Footer>
        </div>
    )
}