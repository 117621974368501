import React from "react"
import { Link } from 'gatsby'
import logo from "../images/logo.svg"

/* Large Banner on Home Page */
const Banner = () => {
    return(
        <section>
            <div className="mask">
                <div  className="center-row" id="banner-parent"> 
                    <div id="banner-content">
                        <li>
                        <img class = "logo" src={logo} alt="Logo Icon"></img>
                        </li>
                        <h1> Closed</h1>
                        <p> It is with a heavy heart that we announce the permanent closure of Co-op Home Care. For many years, we have had the honor and privilege of providing in-home support to our clients, assisting them with daily activities and ensuring their comfort and well-being. Your trust and support have meant the world to us.

We want to express our deepest gratitude to all our clients, their families, and our dedicated caregivers. Your commitment and compassion have been the cornerstone of our service. While this chapter is coming to an end, the memories and bonds we have created will remain with us forever.

Thank you for allowing us to be a part of your lives.. </p>
                        <div id="banner-buttons">
                            <Link to="/services" id="find">Find Care</Link>
                            <Link to="/careers" id="join">Join the Team</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Banner
